@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

h1.herologo {
  margin-top: 1em;
  text-align: center;
  font-size: 3rem;
}

.hero-section {
  margin-top: 50px;
  padding: 0 5%;
  width: 85vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 80vh;
  margin-bottom: -15vh;
  background-image: url("https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Fmechanic-wallpaper%2F23&psig=AOvVaw28KNtYAPYniwAxDUn8eGfx&ust=1726416382588000&source=images&cd=vfe&opi=89978449&ved=0CBQQjRxqFwoTCPj5ufDowogDFQAAAAAdAAAAABAJ");
}

.title {
  text-align: center;
  color: #d64e2e;
  font-weight: bolder;
  font-size: 40px;
  font-family: "Russo One", sans-serif;
  margin-bottom: 30px;
}

#three-robot-model {
  height: 40vw;
  max-width: 500px;
  max-height: 500vh;
  min-width: 300px;
  min-height: 900px;
  margin-right: 60px;
  display: flex;
  justify-content: center;
  margin-top: 3vh;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  margin-top: -7vh;
}

.content h2 {
  color: #d64e2e;
  font-weight: bolder;
  font-size: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.content p {
  color: white;
  font-size: 18px;
  line-height: 1.8;
  margin: 20px 0;
  text-align: center;
  max-width: 600px;
}

.buttons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.buttons button {
  padding: 10px 20px;
  border: 2px solid #d64e2e;
  border-radius: 20px;
  color: white;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.watch-live {
  background-color: #d64e2e;
}

.matches {
  background-color: transparent;
  color: white;
}

button:hover {
  background-color: white;
  color: #d64e2e;
}
@media (max-width: 1300px) {
  #three-robot-model {
    margin-top: -13vh;
  }
  .content {
    margin-top: -40vh;
  }
}
/* Media Query for Small Screens */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15vh;
  }

  #three-robot-model {
    width: 45vw;
    height: 45vw;
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 250px;
    max-height: 250px;
    margin-top: -20vh;
  }

  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  .content {
    text-align: center;
    align-items: center;
    margin-top: -50vh;
  }

  .content h2 {
    font-size: 24px;
  }

  .content p {
    font-size: 16px;
  }

  .buttons {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 15px;
  }

  .buttons button {
    width: 80%;
    max-width: 250px;
  }
}
