@import url("https://fonts.googleapis.com/css2?family=Monument+Extended:wght@400;700&display=swap");
.progressbar-container {
  height: 100vh;
  display: grid;
  place-content: center;
  background-color: #000;
  position: relative;
}

.bar-contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -400px;
  margin-top: -90%;
}

.progressbar {
  width: 300px;
  height: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 60px;
  box-shadow: 0px 0px 20px #d74b06;
  overflow: hidden;
}

.bar {
  width: 0%;
  background: rgb(225, 104, 12);
  background: linear-gradient(
    180deg,
    rgb(225, 104, 12) 0%,
    rgb(225, 104, 12) 32%,
    rgb(225, 104, 12) 100%
  );
}

.loading-text {
  margin-top: 10px;
  font-family: "Monument Extended"; /* You can replace this with any font you like */
  font-size: 24px;
  color: #e66603;
  text-align: center;
}
.loader {
  margin-left: 150px;
  background-color: white;
  width: 110px;
  margin-bottom: 40px;
  height: auto;
}

.logo-container {
  perspective: 1000px;
  height: 50px;
  margin-top: -270px;
  margin-left: -300px;
}

.loading-logo {
  animation: heartbeat 1.7s ease-in-out infinite;
  height: 150px;
  margin-top: 20%;
  margin-left: 13%;
  position: absolute;
  left: 50%;
  width: auto;
}

@keyframes heartbeat {
  0%,
  100% {
    transform: scale(1);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.95);
  }
  60% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(1);
  }
}
@media (max-width: 800px) {
  .logo-container {
    margin-left: -300px;
  }
}

@media (max-width: 400px) {
  .logo-container {
    margin-left: -300px;
  }

  .loader {
    width: 100%; /* Full width on very small screens */
    max-width: none; /* Remove maximum width restriction */
  }
}
