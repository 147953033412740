.faq-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 100px 15vw;
}

.faq-tab {
  position: relative;
  border: 2px solid white;
  border-radius: 5px;
  overflow: hidden;
  padding: 20px;
}
.faq-tab input[type="radio"] {
  display: none; /* Hides the radio buttons */
}
.faq-tab label {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  padding: 10px;
}
.faq-tab label::after {
  content: "+";
  position: absolute;
  right: 20px;
  font-size: 2em;
  color: white;
  transition: transform 1s, color 0.3s;
}

.faq-tab input[type="radio"]:checked + label::after {
  transform: rotate(135deg);
}
.faq-tab label h2 {
  background: #e3571a;
  background: linear-gradient(90deg, #d02514 0%, #e3571a 50%, #ef6d1e 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 7px 7px 5px;
  border-radius: 5px;
  font-size: 1.5rem;
}
.faq-tab label h3 {
  position: relative;
  z-index: 10;
  font-size: 1.5rem;
}
.faq-content {
  max-height: 0;
  overflow: hidden;
  z-index: 10;
}
.faq-tab input[type="radio"]:checked ~ .faq-content {
  max-height: 60vh;
  min-height: 10vh;
}
.faq-content p {
  position: relative;
  padding: 5px;
}
.faq-tab input[type="radio"]:checked ~ label h2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  justify-content: flex-end;
  font-size: 0em;
}
@media only screen and (max-width: 768px) {
  .faqText {
    margin-top: 40%;
  }
  .faq-container {
    margin: 20px;
  }
}
