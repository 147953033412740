.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-color: rgba(128, 128, 128, 0.7);
}
.navbar {
  background: rgb(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  color: white;
  padding: 20px;
}

.nav-item {
  margin-left: 1rem;
}
.navbar img {
  height: 2rem;
}

.link {
  color: white;
}

.link:hover {
  color: #e06f06;
}

.last {
  margin-left: 5vw;
}

.navbar-nav {
  display: flex;
  gap: 3vw;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}

.no-wrap {
  white-space: nowrap;
  margin-left: 2.3vw;
}

/* For mobile view */
@media (max-width: 1000px) {
  .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Translucent background */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Smooth transition */
    transform: translateY(-100%); /* Initially hidden */
    opacity: 0;
  }
  .navbar-collapse ul {
    list-style: none;
    padding-top: 110px;
    margin-top: 400px;
    margin-bottom: 100px; /* Directly below the hamburger menu */
    background-color: rgba(20, 3, 3, 0.7);
    margin-left: -30px; /* Grey transparent background */
    width: 115%;

    text-align: center;
    transition: all 0.3s ease-in-out;
  }
  .navbar-collapse ul li {
    position: relative;
    padding: 10px 0; /* Adjust padding as needed */
  }

  .navbar-collapse ul li::after {
    content: "";
    position: absolute;
    width: 0; /* Start with no width */
    height: 2px; /* Adjust height of underline */
    background-color: rgb(237, 106, 7); /* Orange color for underline */
    bottom: 0;
    left: 0;
    transition: width 0.3s ease; /* Animation for underline */
  }

  .navbar-collapse ul li:hover::after {
    width: 100%; /* Expand to full width on hover */
  }

  .navbar-collapse.show {
    transform: translateY(100%); /* Slide down */
    opacity: 1;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    align-items: center;
    margin-left: 17%;
  }

  .navbar-toggler {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1100;
  }

  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }

  .last {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 1000px) {
    .last {
      display: none;
    }
  }

  .link {
    font-size: 1.5rem;
    margin: 0;
  }
}
.gravitas-logo {
  margin-left: 50px;
}
