/* src/components/CircleEffect.css */
.circle {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000; /* Set lower z-index to avoid covering important elements */
  transition: transform 0.2s ease;
}
