.tournament-container {
    padding: 20px;
    background-color: #1c1c1e;
    color: #ffffff;
    font-family: Arial, sans-serif;
    border-radius: 8px;
}

.tournament-title {
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
    font-size: 24px;
}

.tournament-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #2c2c2e;
    border-radius: 8px;
    overflow: scroll;
}

.tournament-table th,
.tournament-table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #444;
}

.tournament-table th {
    background-color: #333;
    color: #ffffff;
    font-weight: bold;
}

.tournament-table td {
    background-color: #2c2c2e;
    color: #ffffff;
}

.tournament-table tr:hover {
    background-color: #444;
}

.eliminated {
    color: red;
    font-weight: bold;
}

.active {
    color: green;
    font-weight: bold;
}

.status-chip {
    display: inline-block;
    padding: 4px 10px;
    border-radius: 16px;
    font-size: 0.8em;
    color: #fff;
    text-align: center;
    min-width: 70px;
}

.status-chip.active {
    background-color: #4caf50;
}

.status-chip.eliminated {
    background-color: #f44336;
}

.upcoming-title {
    margin-top: 30px;
    text-align: center;
    color: #ffffff;
}

.upcoming-matches-container {
    display: flex;
    overflow-x: auto;
    padding: 10px;
    gap: 10px;
}

.match-card {
    flex: 0 0 auto;
    width: 380px;
    height: 220px;
    padding: 15px;
    background-color: #fff;
    border: 2px solid #f44336;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    color: #000;
    text-align: center;
    margin: 10px;
}

.match-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 5px;
}

hr {
    border: none;
    height: 1px;
    background-color: #000;
    margin: 5px 0;
}

.match-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.team-icon {
    width: 110px;
    height: 90px;
    background-color: #2c2c2e;
    padding: 4px;
    border-radius: 4px;
}

.vs-text {
    font-weight: bold;
    font-size: 1.5em;
    margin: 0 10px;
    padding-top: 30px;
}

.score-section {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-weight: bold;
    font-size: 1.2em;
}


.search-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.search-input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    color: #000;
}


.team-info {
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-icon {
    width: 110px;
    height: 80px;
}

.large-score {
    font-size: 25px;
    font-weight: bold;
    margin: 0 10px;
}

.match-card .match-header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
}

.team_name{
    margin-top: 30px;
    font-size: 1.5rem;
}
